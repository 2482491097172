import React from 'react';
import ReactDOM from 'react-dom';
import { graphql } from 'gatsby';
import Section from '@shared/Section/Section';
import Leadership from '@components/sections/Leadership/Leadership.query';
import Contact from '@components/sections/Contact/Contact.query';
import BrandStory from '@components/sections/BrandStory/BrandStory.query';
import Brands from '@components/sections/Brands/Brands.query';
import Media from '@components/sections/Media/Media.query';
import Header from '@shared/Header/Header';
import Footer from '@shared/Footer/Footer';
import Hero from '@components/Hero/Hero';
import Careers from '@components/sections/Careers/Careers.query';
import Vision from '@components/sections/Vision/Vision.query';
import Meta from '@shared/Meta/Meta.query';
import StoryIntroduction from '@components/StoryIntroduction/StoryIntroduction.query';
import StoryTabs from '@components/StoryTabs/StoryTabs.query';
import GradientBackground from '@shared/GradientBackground/GradientBackground';
import { q, virtualPageView } from '@utils';

const UNTRACKED_SECTIONS = ['Hero'];

class IndexPage extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      currentSection: null,
      pageMounted: false
    };

    this.portalContainer = null;
  }

  componentDidMount() {
    this.portalContainer = q('#layout');
    setTimeout(() => {
      this.setState({ pageMounted: true });
    }, 0);
  }

  setPageSection = newSection => {
    const { newSection: currentSection } = this.state;
    if (currentSection !== newSection) {
      this.setState({ currentSection: newSection });
      const virtualPageUrl = `/#${newSection}`;
      history.pushState({}, '', `/#${newSection}`);
      // only track allowed sections
      if (!UNTRACKED_SECTIONS.includes(newSection)) {
        virtualPageView(virtualPageUrl, newSection);
      }
    }
  };

  render() {
    const { data } = this.props;
    const { currentSection, pageMounted } = this.state;
    const meta = data.allContentfulHome.edges.map(edge => edge.node.meta)[0];
    const navLinks = data.allContentfulHome.edges.map(edge => edge.node.navLinks)[0];
    const heroHeadline = data.allContentfulHome.edges.map(edge => edge.node.heroHeadline)[0];
    const storyIntroduction = data.allContentfulHome.edges.map(edge => edge.node.story.storyIntroduction)[0];
    const storyTabs = data.allContentfulHome.edges.map(edge => edge.node.story.storyTabs)[0];
    const vision = data.allContentfulHome.edges.map(edge => edge.node.vision)[0];
    const leadership = data.allContentfulHome.edges.map(edge => edge.node.leadership)[0];
    const media = data.allContentfulHome.edges.map(edge => edge.node.media)[0];
    const brandStory = data.allContentfulHome.edges.map(edge => edge.node.brandStory)[0];
    const brandStoryLogo = data.file;
    const contact = data.allContentfulHome.edges.map(edge => edge.node.contact)[0];
    const brands = data.allContentfulHome.edges.map(edge => edge.node.brands)[0];
    const careers = data.allContentfulHome.edges.map(edge => edge.node.careers)[0];

    return (
      <>
        {this.portalContainer &&
          ReactDOM.createPortal(
            <GradientBackground currentStep={currentSection} />,
            this.portalContainer
          )}

        <Meta {...meta} />
        <Header
          currentSection={currentSection}
          navLinks={navLinks}
          setPageSection={this.setPageSection}
        />
        <Hero
          canPlay={pageMounted}
          setPageSection={this.setPageSection}
          heroHeadline={heroHeadline}
        />
        <Section
          setPageSection={this.setPageSection}
          transparent
        >
          <StoryIntroduction {...storyIntroduction} />
        </Section>
        <Section title="Media" setPageSection={this.setPageSection} currentSection={currentSection}>
          <Media {...media} />
        </Section>
        <Section
          title="Story"
          setPageSection={this.setPageSection}
          transparent
          currentSection={currentSection}
        >
          <StoryTabs {...storyTabs} />
        </Section>
        <Section
          title="Vision"
          setPageSection={this.setPageSection}
          currentSection={currentSection}
        >
          <Vision {...vision} />
        </Section>
        <Section
          title="Leadership"
          setPageSection={this.setPageSection}
          currentSection={currentSection}
        >
          <Leadership {...leadership} />
        </Section>
        <Section
          title="Brands"
          setPageSection={this.setPageSection}
          currentSection={currentSection}
        >
          <BrandStory copy={brandStory} brandLogo={brandStoryLogo} />
          <Brands {...brands} />
        </Section>
        <Section
          title="Careers"
          setPageSection={this.setPageSection}
          currentSection={currentSection}
        >
          <Careers {...careers} />
        </Section>
        <Section
          title="Contact"
          setPageSection={this.setPageSection}
          currentSection={currentSection}
        >
          <Contact {...contact} />
        </Section>
        <Footer currentGradientStep={currentSection} />
      </>
    );
  }
}

export default IndexPage;

// Graphql Query
export const pageQuery = graphql`
  {
    allContentfulHome {
      edges {
        node {
          meta {
            ...Meta
          }
          navLinks {
            displayLabel
            sectionId
          }
          heroHeadline
          story {
            storyIntroduction {
              ...StoryIntroduction
            }
            storyTabs {
              ...StoryTabs
            }
          }
          vision {
            ...Vision
          }
          leadership {
            ...Leadership
          }
          brands {
            ...Brands
          }
          careers {
            ...Careers
          }
          media {
            ...Media
          }
          ...BrandStory
          contact {
            ...Contact
          }
        }
      }
    }
    ...BrandStoryLogo
  }
`;
