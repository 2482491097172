import React from 'react';
import { graphql } from 'gatsby';
import idx from 'idx';
import Careers from './Careers';

const CareersQuery = props => {
  const eyebrow = idx(props, _ => _.eyebrow);
  const headline = idx(props, _ => _.headline);
  const jobOpenings = idx(props, _ =>
    _.jobOpenings.map(opening => {
      return {
        title: idx(opening, _ => _.title),
        location: idx(opening, _ => _.location),
        externalUrl: idx(opening, _ => _.externalUrl)
      };
    })
  );

  const allPositionsLink = idx(props, _ => _.allPositionsLink);

  return (
    <Careers
      eyebrow={eyebrow}
      headline={headline}
      jobOpenings={jobOpenings}
      allPositionsLink={allPositionsLink}
    />
  );
};

export default CareersQuery;

export const query = graphql`
  fragment Careers on ContentfulCareers {
    eyebrow
    headline
    jobOpenings {
      title
      location
      externalUrl
    }
    allPositionsLink
  }
`;
