import React from 'react';
import { css } from '@utils';

import styles from './CarouselButton.module.scss';

const CarouselButton = props => {
  const { color, flipped, inView, className, onClick } = props;
  return (
    <>
      <div
        className={css(styles.carouselButton, flipped && styles.carouselButtonFlipped, className)}
      >
        <div className={css(styles.buttonWrapper, flipped && styles.buttonWrapperFlipped)}>
          <button
            aria-label={flipped ? 'Carousel Left Button' : 'Carousel Right Button'}
            type="button"
            className={css(
              styles.button,
              flipped && styles.flipped,
              inView && styles.inView,
              color && styles[color]
            )}
            onClick={e => {
              if (onClick) {
                onClick(e);
              }
            }}
          >
            <svg
              className={css(styles.arrow)}
              width="11.621"
              height="20.414"
              viewBox="0 0 11.621 20.414"
            >
              <path
                d="M2699.043,6036.32l9.5,9.5-9.5,9.5"
                transform="translate(-2698.336 -6035.613)"
                fill="none"
                stroke="#fff"
                strokeWidth="2"
              />
            </svg>
          </button>
        </div>
      </div>
    </>
  );
};

export default CarouselButton;
