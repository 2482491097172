import React from 'react';
import CharacterReveal from '@shared/Reveals/CharacterReveal';
import Reveal from '@shared/Reveals/Reveal';
import InView from '@shared/InView/InView';
import PersonnelCarousel from '@shared/PersonnelCarousel/PersonnelCarousel';
import { css } from '@utils';

import styles from './Leadership.module.scss';

const Leadership = props => {
  const { title, headline, leaders } = props;
  return (
    <>
      <div className={css(styles.leadership)}>
        <div className={css(styles.leadershipHeader)}>
          <InView triggerOnce>
            {inView => {
              return (
                <>
                  <Reveal
                    animateOpacity
                    canPlay={inView}
                    from="bottom"
                    duration={1275}
                    ease="cubic-bezier(0,0.4,0.4,1)"
                    offset="45px"
                  >
                    <p className={css(styles.eyebrow, inView && styles.inView)}>{title}</p>
                  </Reveal>
                  <Reveal
                    animateOpacity
                    canPlay={inView}
                    from="bottom"
                    delay={500}
                    duration={700}
                    ease="cubic-bezier(0,0.4,0.4,1)"
                    offset="45px"
                  >
                    <div className={css(styles.headline, inView && styles.inView)}>{[headline]}</div>
                  </Reveal>
                </>
              );
            }}
          </InView>
        </div>
        <PersonnelCarousel personnel={leaders} currentSection="Leadership" />
      </div>
    </>
  );
};

export default Leadership;
