import React from 'react';
import Reveal from '@shared/Reveals/Reveal';
import InView from '@shared/InView/InView';
import { css, trackEvent } from '@utils';
import styles from './Careers.module.scss';

class Careers extends React.PureComponent {
  constructor() {
    super();
    this.state = { hovered: -1 };
  }

  onMouseEnter = jobIndex => {
    this.setState({ hovered: jobIndex });
  };

  onMouseLeave = () => {
    this.setState({ hovered: -1 });
  };

  onJobLinkClick = (e, job, jobIndex) => {
    e.preventDefault();
    const newWindow = window.open(job.externalUrl, '_blank');
    newWindow.opener = null;
    trackEvent(
      { category: 'Careers', action: job.externalUrl, label: `${job.title}_${job.location}` },
      { position: jobIndex + 1 }
    );
  };

  onAllPositionsLinkClick = (e, allPositionsLink) => {
    e.preventDefault();
    const newWindow = window.open(allPositionsLink, '_blank');
    newWindow.opener = null;
    trackEvent({ category: 'Careers', action: allPositionsLink, label: 'All Positions' });
  };

  render() {
    const { eyebrow, headline, jobOpenings, allPositionsLink } = this.props;
    const { hovered } = this.state;
    return (
      <div className={css(styles.wrapper)}>
        <div className={css(styles.headerContent)}>
          <InView triggerOnce>
            {inView => {
              return (
                <>
                  <Reveal
                    animateOpacity
                    canPlay={inView}
                    from="bottom"
                    duration={1275}
                    ease="cubic-bezier(0,0.4,0.4,1)"
                    offset="45px"
                  >
                    <p className={css(styles.eyebrow, inView && styles.inView)}>{eyebrow}</p>
                  </Reveal>
                  <Reveal
                    animateOpacity
                    canPlay={inView}
                    from="bottom"
                    delay={500}
                    duration={700}
                    ease="cubic-bezier(0,0.4,0.4,1)"
                    offset="45px"
                  >
                    <div className={css(styles.headline, inView && styles.inView)}>{[headline]}</div>
                  </Reveal>
                </>
              );
            }}
          </InView>
        </div>
        <div className={css(styles.jobsListWrapper)}>
          <InView className={css(styles.jobOpeningInViewWrapper)} triggerOnce>
            {inView => {
              return (
                <ul className={css(styles.jobsList)}>
                  {jobOpenings.map((job, index) => {
                    return (
                      <Reveal
                        className={css(styles.jobOpeningReveal)}
                        key={`JobOpening__${index}`}
                        animateOpacity
                        canPlay={inView}
                        from="bottom"
                        delay={1000 + index * 100}
                        duration={500}
                        ease="cubic-bezier(0,0.4,0.4,1)"
                        offset="45px"
                      >
                        <li
                          className={css(
                            styles.jobOpening,
                            hovered === index && styles.hovered,
                            hovered - 1 === index && styles.noBottomBorder
                          )}
                          onMouseEnter={() => {
                            this.onMouseEnter(index);
                          }}
                          onMouseLeave={this.onMouseLeave}
                        >
                          <a
                            href={job.externalUrl}
                            rel="noopener noreferrer"
                            target="_blank"
                            className={css(styles.jobOpeningContentWrapper)}
                            onClick={e => this.onJobLinkClick(e, job, index)}
                          >
                            <div className={css(styles.jobInfoWrapper)}>
                              <div className={css(styles.jobTitle)}>{job.title}</div>
                              <div className={css(styles.jobLocation)}>{job.location}</div>
                            </div>
                            <div className={css(styles.arrowIcon)}>
                              <JobArrow />
                            </div>
                          </a>
                        </li>
                      </Reveal>
                    );
                  })}
                </ul>
              );
            }}
          </InView>
        </div>
        <InView className={css(styles.allPositionsInViewWrapper)} triggerOnce>
          {inView => {
            return (
              <Reveal
                animateOpacity
                canPlay={inView}
                from="bottom"
                delay={1450}
                duration={500}
                ease="cubic-bezier(0,0.4,0.4,1)"
                offset="45px"
              >
                <div className={css(styles.allPositionsLink)}>
                  <a
                    href={allPositionsLink}
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={e => this.onAllPositionsLinkClick(e, allPositionsLink)}
                  >
                    <span className={css(styles.allPositionsLinkCopy)}>All Positions</span>
                    <span>
                      <LinkArrow />
                    </span>
                  </a>
                </div>
              </Reveal>
            );
          }}
        </InView>
      </div>
    );
  }
}

export default Careers;

const JobArrow = () => {
  return (
    <svg className={css(styles.arrow)} width="11.621" height="20.414" viewBox="0 0 11.621 20.414">
      <path
        d="M2699.043,6036.32l9.5,9.5-9.5,9.5"
        transform="translate(-2698.336 -6035.613)"
        fill="none"
        strokeWidth="2"
      />
    </svg>
  );
};

const LinkArrow = () => {
  return (
    <svg width="19.272" height="9.148" viewBox="0 0 19.272 9.148">
      <g transform="translate(3.685 -4005.41)">
        <g transform="translate(-3.685 4005.789)">
          <path
            d="M815.7,492.551l4.531,3.884-4.531,4.531"
            transform="translate(-801.694 -492.551)"
            fill="none"
            stroke="#33293d"
            strokeMiterlimit="10"
            strokeWidth="1"
          />
          <line
            x1="18.538"
            transform="translate(0 3.941)"
            fill="none"
            stroke="#33293d"
            strokeMiterlimit="10"
            strokeWidth="1"
          />
        </g>
      </g>
    </svg>
  );
};
