import React from 'react';
import ReactDOM from 'react-dom';
import LogoSymbol from '@shared/Logos/LogoSymbol';
import GradientBackground from '@shared/GradientBackground/GradientBackground';
import ScrollContext from '@context/ScrollContext';
import styles from './HeaderCondensed.module.scss';
import { css, q, scrollTo, constrain, trackEvent, isIE } from '@utils';

class HeaderCondensed extends React.PureComponent {
  constructor() {
    super();
    this.state = { portalContainer: null };
    this.isIE = false;
  }

  UNSAFE_componentWillMount() {
    const { removeScrollCallback } = this.context;
    removeScrollCallback(this.onScroll);
  }

  componentDidMount() {
    const { addScrollCallback } = this.context;
    this.setState({
      portalContainer: q('#layout')
    });

    this.isIE = isIE();

    addScrollCallback(this.onScroll);
  }

  onScroll = ({ scrollTop, windowInnerHeight }) => {
    const { active } = this.state;
    // Show condensed header.
    if (scrollTop > windowInnerHeight - 50) {
      if (!active) {
        this.setState({ active: true });
      }
    } else if (active) {
      // Hide condensed header.
      this.setState({ active: false });
    }
  };

  onClick = sectionId => {
    const sectionEl = q(`#${sectionId}`);
    const headerHeight = 60;
    const newScrollPosition = sectionEl ? sectionEl.offsetTop : 0;

    // Since our condensed header sits on top of the content, we should subtract its height from the new scroll position
    const scrollAdjustedForHeader = constrain(
      newScrollPosition - headerHeight,
      0,
      newScrollPosition
    );

    // This makes it so you don't fully animate ALL the way to the new section. It starts you 50px away and then eases you into the section.

    window.scrollTo(0, scrollAdjustedForHeader);

    if (sectionEl) {
      scrollTo(scrollAdjustedForHeader, 500);
    }

    trackEvent({ category: 'Header', action: 'Navigation', label: sectionId });
  };

  render() {
    const { currentSection, navLinks } = this.props;
    const { portalContainer, active } = this.state;

    return (
      <>
        {portalContainer &&
          ReactDOM.createPortal(
            <header className={css(styles.condensedHeader, active && styles.active)}>
              <GradientBackground
                className={css(this.isIE && styles.gradientBackground)}
                currentStep={currentSection}
              />
              <div className={css(styles.condensedHeaderContent)}>
                <button
                  tabIndex="1"
                  type="button"
                  onClick={() => {
                    scrollTo(0, 500);
                  }}
                  className={css(styles.logoSymbol)}
                >
                  <LogoSymbol />
                </button>
                <ul className={css(styles.linksList)}>
                  {navLinks.map((link, index) => {
                    return (
                      <li
                        key={`HeaderCondensedLink__${index}`}
                        onClick={() => this.onClick(link.sectionName)}
                        className={css(
                          styles.link,
                          currentSection === link.sectionName && styles.active
                        )}
                      >
                        {link.label}
                      </li>
                    );
                  })}
                </ul>
              </div>
            </header>,
            portalContainer
          )}
      </>
    );
  }
}

HeaderCondensed.contextType = ScrollContext;
export default HeaderCondensed;
