import React from 'react';
import Img from 'gatsby-image';
import Parallax from '@shared/Parallax/Parallax';
import idx from 'idx';
import Reveal from '@shared/Reveals/Reveal';
import InView from '@shared/InView/InView';
import ImageReveal from '@shared/ImageReveal/ImageReveal';
import { css, constrain, addZeroPrefix, trackEvent } from '@utils';
import styles from './StoryTabs.module.scss';

const MOBILE_BREAKPOINT = 768;

class StoryTabs extends React.PureComponent {
  constructor() {
    super();
    this.state = { active: 0, windowWidth: 0 };
    this.animationCopyDirection = 'left';
  }

  componentDidMount() {
    window.addEventListener('resize', this.onResize);
    this.onResize();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.onResize);
  }

  onResize = () => {
    this.setState({ windowWidth: window.innerWidth });
  };

  onTabClick = tabIndex => {
    const { tabs } = this.props;
    const { active } = this.state;
    this.animationCopyDirection = active > tabIndex ? 'left' : 'right';
    const constrainedIndex = constrain(tabIndex, 0, tabs.length - 1);
    this.setState({ active: constrainedIndex });

    trackEvent({
      category: 'Our Story',
      action: 'Navigation',
      label: tabs[tabIndex] ? tabs[tabIndex].title : ''
    });
  };

  getCarouselPosition = () => {
    const { active } = this.state;
    const { tabs } = this.props;

    const transformXPercentage = -1 * (active * ((1 / tabs.length) * 100));
    return {
      transform: `translate3d(${transformXPercentage}%, 0, 0)`
    };
  };

  getImageHeightPlaceholder = () => {
    // By using a placeholder image set to 0 opacity, we can keep the appropriate height for a section when it has absolutely positioned children.
    const { windowWidth } = this.state;
    const { tabs } = this.props;
    const isMobile = windowWidth < MOBILE_BREAKPOINT;

    // All images should have the same aspect ratio. So we can just use the first tab image aspect ratio.
    const imageAspectRatio = isMobile
      ? idx(tabs, _ => _[0].imageMobile.fluid.aspectRatio)
      : idx(tabs, _ => _[0].imageDesktop.fluid.aspectRatio);
    return (
      <div
        style={{
          opacity: '0',
          width: '100%',
          paddingBottom: `${(1 / imageAspectRatio) * 100}%`
        }}
      />
    );
  };

  getCopyHeightPlaceholder = () => {
    // Let the browser handle calculating the height of the copy area instead of doing any getBoundingClientRect() calculations on our own.
    // Setting the placeholder to opacity 0 will give the correct padding to our absolutely positioned copy carousel.

    const { tabs } = this.props;
    return (
      <div className={css(styles.copyHeightPlaceholder)}>
        {tabs.map((tab, index) => {
          return (
            <div
              key={`CopyPlaceholder__${index}`}
              className={css(styles.tabItemWrapperPlaceholder)}
            >
              <div className={css(styles.tabItemIndex)}>{addZeroPrefix(index + 1)}</div>
              <h3 className={css(styles.tabItemHeadline)}>{tab.headline}</h3>
              <div className={css(styles.tabItemCopy)}>{tab.copy}</div>
            </div>
          );
        })}
      </div>
    );
  };

  render() {
    const { active, windowWidth } = this.state;
    const { eyebrow, headline, tabs } = this.props;
    const isMobile = windowWidth < MOBILE_BREAKPOINT;
    const carouselPosition = this.getCarouselPosition();
    const imageHeightPlaceholder = this.getImageHeightPlaceholder();
    const copyHeightPlaceholder = this.getCopyHeightPlaceholder();

    return (
      <div className={css(styles.wrapper)}>
        <InView className={css(styles.inViewWrapper)} triggerOnce>
          {inView => {
            return (
              <>
                <Reveal
                  animateOpacity
                  canPlay={inView}
                  from="bottom"
                  duration={1275}
                  ease="cubic-bezier(0,0.4,0.4,1)"
                  offset="45px"
                >
                  <p className={css(styles.eyebrow, inView && styles.inView)}>{eyebrow}</p>
                </Reveal>
                <Reveal
                  animateOpacity
                  canPlay={inView}
                  from="bottom"
                  duration={700}
                  delay={500}
                  ease="cubic-bezier(0,0.4,0.4,1)"
                  offset="45px"
                >
                  <h2 className={css(styles.headline, inView && styles.inView)}>{[headline]}</h2>
                </Reveal>
              </>
            );
          }}
        </InView>
        <div className={css(styles.storyTabs)}>
          <div className={css(styles.tabLinksWrapper)}>
            <InView className={css(styles.tabLinksInViewWrapper)} triggerOnce>
              {inView => {
                return (
                  <>
                    <button
                      type="button"
                      className={css(styles.tabLinkArrow, styles.left, inView && styles.inView)}
                      onClick={() => this.onTabClick(active - 1)}
                    >
                      <Arrow active={active - 1 >= 0} />
                    </button>
                    <button
                      type="button"
                      className={css(styles.tabLinkArrow, styles.right, inView && styles.inView)}
                      onClick={() => this.onTabClick(active + 1)}
                    >
                      <Arrow active={active + 1 <= tabs.length - 1} />
                    </button>

                    <div
                      className={css(styles.tabLinksList)}
                      style={isMobile ? carouselPosition : {}}
                    >
                      {tabs.map((tab, index) => {
                        return (
                          <Reveal
                            key={`TabSection__${index}`}
                            className={css(
                              styles.tabLink,
                              index === active && styles.active,
                              inView && styles.inView
                            )}
                            animateOpacity
                            canPlay={inView}
                            from="bottom"
                            delay={1000 + index * 250}
                            duration={1275}
                            ease="cubic-bezier(0,0.4,0.4,1)"
                            offset="20px"
                          >
                            <button
                              type="button"
                              className={css(styles.tabLinkText, 'alt-focus-visible')}
                              onClick={() => this.onTabClick(index)}
                              tabIndex={isMobile ? '-1' : '0'}
                            >
                              {tab.title}
                            </button>
                            <div
                              className={css(
                                styles.tabLinkUnderline,
                                index === active && styles.active,
                                inView && styles.inView
                              )}
                            />
                          </Reveal>
                        );
                      })}
                    </div>
                  </>
                );
              }}
            </InView>
          </div>
          <div className={css(styles.tabContentWrapper)}>
            <div className={css(styles.imageListWrapper)}>
              {imageHeightPlaceholder}
              <div className={css(styles.imageList)} style={carouselPosition}>
                {tabs.map((tab, index) => {
                  const image = isMobile ? tab.imageMobile : tab.imageDesktop;

                  return (
                    <div
                      key={`TabContentImage__${index}`}
                      className={css(styles.image, active === index && styles.active)}
                    >
                      {/* Use our image reveal animation on the first image in carousel */}
                      {index === 0 ? (
                        <Parallax>
                          <ImageReveal alt={image.description} image={image.fluid} triggerOnce />
                        </Parallax>
                      ) : (
                        <Parallax>
                          <Img alt={image.description} fluid={image.fluid} />
                        </Parallax>
                      )}
                    </div>
                  );
                })}
              </div>
            </div>
            <div className={css(styles.tabCopyWrapper)}>
              {/* Mobile needs a height placeholder based on the copy height */}
              {isMobile && copyHeightPlaceholder}

              {/* Desktop needs a height placeholder based on the image height */}
              {!isMobile && (
                <div className={css(styles.tabItemHeightPlaceholder)}>{imageHeightPlaceholder}</div>
              )}

              {tabs.map((tab, index) => {
                const image = isMobile ? tab.imageMobile : tab.imageDesktop;

                return (
                  <div
                    key={`TabContentCopy__${index}`}
                    className={css(
                      styles.tabItemContent,
                      active >= index ? styles.left : styles.right,
                      active === index && styles.active
                    )}
                  >
                    {!isMobile && (
                      <Img className={css(styles.tabItemHeightPlaceholder)} fluid={image.fluid} />
                    )}

                    {/* Animate in the first tabItem */}
                    {index === 0 && (
                      <InView className={css(styles.tabItemWrapper)} triggerOnce>
                        {inView => {
                          return (
                            <>
                              <Reveal
                                animateOpacity
                                canPlay={inView}
                                className={css(styles.tabItemIndexReveal)}
                                from="bottom"
                                delay={isMobile ? 0 : 1200}
                                duration={1275}
                                ease="cubic-bezier(0,0.4,0.4,1)"
                                offset="45px"
                              >
                                <div className={css(styles.tabItemIndex)}>
                                  {addZeroPrefix(index + 1)}
                                </div>
                              </Reveal>

                              <h3 className={css(styles.tabItemHeadline)}>
                                <Reveal
                                  animateOpacity
                                  canPlay={inView}
                                  from="bottom"
                                  delay={isMobile ? 200 : 1400}
                                  duration={1275}
                                  ease="cubic-bezier(0,0.4,0.4,1)"
                                  offset="45px"
                                >
                                  {tab.headline}
                                </Reveal>
                              </h3>
                              <div className={css(styles.tabItemCopy)}>
                                <Reveal
                                  animateOpacity
                                  canPlay={inView}
                                  from="bottom"
                                  delay={isMobile ? 400 : 1600}
                                  duration={1275}
                                  ease="cubic-bezier(0,0.4,0.4,1)"
                                  offset="45px"
                                >
                                  {tab.copy}
                                </Reveal>
                              </div>
                            </>
                          );
                        }}
                      </InView>
                    )}

                    {/* Don't need to animate the other tab items */}
                    {index !== 0 && (
                      <div className={css(styles.tabItemWrapper)}>
                        <div className={css(styles.tabItemIndex)}>{addZeroPrefix(index + 1)}</div>
                        <h3 className={css(styles.tabItemHeadline)}>{tab.headline}</h3>
                        <div className={css(styles.tabItemCopy)}>{tab.copy}</div>
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
          </div>
        </div>

        <InView triggerOnce>
          {inView => {
            return <div className={css(styles.sectionDivider, inView && styles.inView)} />;
          }}
        </InView>
      </div>
    );
  }
}

const Arrow = props => {
  const { active } = props;

  return (
    <svg
      className={css(styles.arrow, active && styles.active)}
      width="11.621"
      height="20.414"
      viewBox="0 0 11.621 20.414"
    >
      <path
        d="M2699.043,6036.32l9.5,9.5-9.5,9.5"
        transform="translate(-2698.336 -6035.613)"
        fill="none"
        strokeWidth="2"
      />
    </svg>
  );
};

export default StoryTabs;
