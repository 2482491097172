import React from 'react';
import Img from 'gatsby-image';
import { throttle } from 'throttle-debounce';
import { Swipeable } from 'react-swipeable';
import Reveal from '@shared/Reveals/Reveal';
import InView from '@shared/InView/InView';
import CarouselButton from '@shared/CarouselButton/CarouselButton';
import { css, breakpoint, trackEvent } from '@utils';
import styles from './Brands.module.scss';

const IMAGE_WIDTH_LARGE = 540;
const IMAGE_SPACING_LARGE = 15;
const VISIBLE_IMAGES_LARGE = 3;

const IMAGE_WIDTH = 540;
const IMAGE_SPACING = 15;
const VISIBLE_IMAGES = 5;

const IMAGE_WIDTH_MOBILE = 310;
const IMAGE_SPACING_MOBILE = 10;
const VISIBLE_IMAGES_MOBILE = 3;

const SWIPE_CONFIG = {
  delta: 10, // min distance(px) before a swipe starts
  preventDefaultTouchmoveEvent: false, // preventDefault on touchmove, *See Details*
  trackTouch: true, // track touch input
  trackMouse: false // track mouse input
};

class Brands extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      carouselPosition: 0,
      windowWidth: 0
    };
  }

  componentDidMount() {
    this.onResize();
    this.addEventListeners();
  }

  setSlide = throttle(500, true, index => {
    const newSlide = index;
    this.setState({ carouselPosition: newSlide });
  });

  addEventListeners = () => {
    window.addEventListener('resize', this.onResize);
  };

  removeEventListeners = () => {
    window.removeEventListener('resize', this.onResize);
  };

  previousSlide = () => {
    const { carouselPosition } = this.state;
    this.setSlide(carouselPosition - 1);
  };

  nextSlide = () => {
    const { carouselPosition } = this.state;
    this.setSlide(carouselPosition + 1);
  };

  onResize = () => {
    this.setState({ windowWidth: window.innerWidth });
  };

  onLinkClick = (e, brand, brandIndex) => {
    e.preventDefault();
    const newWindow = window.open(brand.link, '_blank');
    newWindow.opener = null;
    trackEvent(
      { category: 'Brands', action: brand.link, label: brand.name },
      { position: brandIndex + 1 }
    );
  };

  componentDidUmount() {
    this.removeEventListeners();
  }

  render() {
    const { windowWidth, carouselPosition } = this.state;
    const { brands, headline, title } = this.props;

    const config = SWIPE_CONFIG;

    let imageWidth = IMAGE_WIDTH_MOBILE;
    let imageSpacing = IMAGE_SPACING_MOBILE;
    let visibleImages = VISIBLE_IMAGES_MOBILE;

    if (windowWidth >= breakpoint.desktopWide) {
      imageWidth = IMAGE_WIDTH_LARGE;
      imageSpacing = IMAGE_SPACING_LARGE;
      visibleImages = VISIBLE_IMAGES_LARGE;
    } else if (windowWidth >= breakpoint.tabletWide) {
      imageWidth = IMAGE_WIDTH;
      imageSpacing = IMAGE_SPACING;
      visibleImages = VISIBLE_IMAGES;
    }

    const currentPerson = carouselPosition % brands.length;
    const brandsHead = currentPerson === 0 ? [] : brands.slice(0, currentPerson);
    const brandsTail = brands.slice(currentPerson);
    const reorderedBrands = brandsTail.concat(brandsHead);

    const modifiedBrands = reorderedBrands.concat(reorderedBrands.concat(reorderedBrands));

    const slides = modifiedBrands.map((brand, index) => {
      const modifiedIndex = index - brands.length + carouselPosition;
      const positionOffset = modifiedIndex - carouselPosition;
      const imageOffset = positionOffset * (imageWidth + imageSpacing);
      const isActive = modifiedIndex === carouselPosition;
      let imageOpacity = (visibleImages * 0.5 - Math.abs(positionOffset)) / (visibleImages * 0.5);
      let isDisabled = imageOpacity <= 0;
      if (windowWidth >= breakpoint.tabletWide && windowWidth < breakpoint.desktopWide) {
        imageOpacity = (visibleImages * 0.5 - Math.abs(positionOffset) + 1) / (visibleImages * 0.5);
        if (positionOffset < 0) {
          isDisabled = true;
        }
      }

      return (
        <div
          aria-hidden={positionOffset !== 0}
          key={`${brand.name}-${modifiedIndex}`}
          data-distance={Math.abs(positionOffset)}
          className={css(
            styles.brand,
            isActive && styles.brandActive,
            isDisabled && styles.brandDisabled
          )}
        >
          {brand.link ? (
            <a
              href={brand.link}
              tabIndex={positionOffset === 0 ? '0' : '-1'}
              target="_blank"
              rel="noopener noreferrer"
              className={css(styles.brandWrapper)}
              style={{
                transform: `translate3d(${imageOffset}px, 0, 0)`,
                opacity: isDisabled ? 0 : imageOpacity
              }}
              onClick={e => this.onLinkClick(e, brand, modifiedIndex)}
            >
              <div className={css(styles.brandAnim)}>
                <Img fluid={brand.backgroundImage.fluid} className={css(styles.backgroundImage)} />
              </div>
              <div className={css(styles.brandContent)}>
                <Img fluid={brand.logo.fluid} className={css(styles.logo)} />
                <p className={css(styles.description)}>{brand.description}</p>
                <span className={css(styles.explore)}>Explore</span>
              </div>
            </a>
          ) : (
            <div
              className={css(styles.brandWrapper)}
              style={{
                transform: `translate3d(${imageOffset}px, 0, 0)`,
                opacity: isDisabled ? 0 : imageOpacity
              }}
            >
              <div className={css(styles.brandAnim)}>
                <Img
                  fluid={brand.backgroundImage.fluid}
                  className={css(styles.backgroundImage)}
                  alt={brand.backgroundImage.description}
                />
              </div>
              <div className={css(styles.brandContent)}>
                <Img
                  fluid={brand.logo.fluid}
                  className={css(styles.logo)}
                  alt={brand.logo.description}
                />
                <p className={css(styles.description)}>{brand.description}</p>
                <span className={css(styles.explore)}>Coming Soon</span>
              </div>
            </div>
          )}
        </div>
      );
    });

    return (
      <>
        <div className={css(styles.brands)}>
          <InView triggerOnce>
            {inView => {
              return <div className={css(styles.divider, inView && styles.inView)} />;
            }}
          </InView>
          <div className={css(styles.brandsContent)}>
            <InView triggerOnce>
              {inView => {
                return (
                  <>
                    <Reveal
                      animateOpacity
                      canPlay={inView}
                      from="bottom"
                      duration={1275}
                      ease="cubic-bezier(0,0.4,0.4,1)"
                      offset="45px"
                    >
                      <p className={css(styles.eyebrow, inView && styles.inView)}>{title}</p>
                    </Reveal>
                    <Reveal
                      animateOpacity
                      canPlay={inView}
                      from="bottom"
                      delay={500}
                      duration={700}
                      ease="cubic-bezier(0,0.4,0.4,1)"
                      offset="45px"
                    >
                      <div className={css(styles.headline, inView && styles.inView)}>
                        {[headline]}
                      </div>
                    </Reveal>
                  </>
                );
              }}
            </InView>
            <InView className={css(styles.carousel)} triggerOnce>
              {inView => {
                return (
                  <>
                    <Swipeable
                      className={css(styles.carouselSlides, inView && styles.carouselSlidesInView)}
                      onSwipedRight={() => this.previousSlide()}
                      onSwipedLeft={() => this.nextSlide()}
                      {...config}
                    >
                      {slides}
                    </Swipeable>
                    <CarouselButton
                      color="blue"
                      onClick={this.previousSlide}
                      inView={inView}
                      flipped
                    />
                    <CarouselButton color="yellow" onClick={this.nextSlide} inView={inView} />
                  </>
                );
              }}
            </InView>
          </div>
          <InView triggerOnce>
            {inView => {
              return <div className={css(styles.sectionDivider, inView && styles.inView)} />;
            }}
          </InView>
        </div>
      </>
    );
  }
}

export default Brands;
