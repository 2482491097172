import React from 'react';
import { graphql } from 'gatsby';
import idx from 'idx';
import Media from './Media';

const MediaQuery = props => {
  const title = idx(props, _ => _.title);
  const mediaQuotes = idx(props, _ => _.mediaQuotes);
  const mediaDownloads = idx(props, _ => _.mediaDownloads);
  const quotes = mediaQuotes
    ? mediaQuotes.map(quote => {
        const quoteFormatted = {};
        quoteFormatted.publication = idx(quote, _ => _.publication);
        quoteFormatted.copy = idx(quote, _ => _.quote);
        quoteFormatted.link = idx(quote, _ => _.link);
        quoteFormatted.logo = idx(quote, _ => _.publicationLogo);
        return quoteFormatted;
      })
    : [];
  const downloads = mediaDownloads
    ? mediaDownloads.map(download => {
        const downloadFormatted = {};
        downloadFormatted.title = idx(download, _ => _.title);
        downloadFormatted.link = idx(download, _ => _.file.url);
        return downloadFormatted;
      })
    : [];
  return <Media quotes={quotes} downloads={downloads} title={title} />;
};

export default MediaQuery;

export const query = graphql`
  fragment Media on ContentfulMedia {
    title
    mediaDownloads {
      title
      file {
        url
        fileName
        contentType
      }
    }
    mediaQuotes {
      publication
      publicationLogo {
        fluid(maxWidth: 500, quality: 100) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
      quote
      link
    }
  }
`;
