import React from 'react';
import Img from 'gatsby-image';
import { throttle } from 'throttle-debounce';
import { Swipeable } from 'react-swipeable';
import Reveal from '@shared/Reveals/Reveal';
import InView from '@shared/InView/InView';
import CarouselButton from '@shared/CarouselButton/CarouselButton';
import { css, trackEvent } from '@utils';
import styles from './Media.module.scss';

const SWIPE_CONFIG = {
  delta: 10, // min distance(px) before a swipe starts
  preventDefaultTouchmoveEvent: false, // preventDefault on touchmove, *See Details*
  trackTouch: true, // track touch input
  trackMouse: false // track mouse input
};

class Media extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      carouselPosition: 0
    };
  }

  componentDidMount = () => {
    this.downloadSelectRef.addEventListener('change', this.onDownloadChange);
  };

  componentWillUnmount = () => {
    this.downloadSelectRef.removeEventListener('change', this.onDownloadChange);
  };

  setSlide = throttle(1000, true, index => {
    const newSlide = index;
    this.setState({ carouselPosition: newSlide });
  });

  previousSlide = () => {
    const { carouselPosition } = this.state;
    this.setSlide(carouselPosition - 1);
  };

  nextSlide = () => {
    const { carouselPosition } = this.state;
    this.setSlide(carouselPosition + 1);
  };

  onDownloadChange = e => {
    e.preventDefault();
    if (e.target.value) {
      if (e.target.selectedOptions) {
        trackEvent({
          category: 'Media',
          action: 'Download',
          label: `Mobile - ${e.target.selectedOptions[0].innerText}`
        });
      }
      window.location = e.target.value;
    }
  };

  onDownloadMedia = (e, mediaLink, mediaTitle) => {
    e.preventDefault();
    trackEvent({ category: 'Media', action: 'Download', label: `${mediaTitle}` });
    const newWindow = window.open(mediaLink, '_blank');
    newWindow.opener = null;
  };

  render() {
    const { carouselPosition } = this.state;
    const { quotes, title, downloads } = this.props;

    const config = SWIPE_CONFIG;

    const currentQuote = carouselPosition % quotes.length;
    const quotesHead = currentQuote === 0 ? [] : quotes.slice(0, currentQuote);
    const quotesTail = quotes.slice(currentQuote);
    const reorderedQuotes = quotesTail.concat(quotesHead);

    const modifiedQuote = reorderedQuotes.concat(reorderedQuotes);

    const downloadLinks = downloads.map((download, index) => {
      return (
        <div className={css(styles.downloadLinkWrapper)} key={`${title}-${index}`}>
          <a
            href={download.link}
            className={css(styles.downloadLink, styles.generalDownload)}
            onClick={e => this.onDownloadMedia(e, download.link, download.title)}
          >
            <div className={css(styles.downloadLinkContent)}>
              <svg
                height="13.99"
                viewBox="0 0 13.523 13.99"
                width="13.523"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g fill="none" strokeMiterlimit="10">
                  <path d="m0 13.089h13.523v.902h-13.523z" />
                </g>
                <g fill="none" strokeMiterlimit="10">
                  <path d="m0 0 5.7 4.889-5.7 5.703" transform="matrix(0 1 -1 0 12.145 3.499)" />
                  <path d="m9.203 0h-9.203" transform="matrix(0 1 -1 0 7.185 0)" />
                </g>
              </svg>
              <span>{download.title}</span>
            </div>
          </a>
        </div>
      );
    });
    const downloadOptions = downloads.map((download, index) => {
      return (
        <option
          value={download.link}
          className={css(styles.downloadLinkMobileOption)}
          key={`${title}-${index}`}
        >
          {download.title}
        </option>
      );
    });
    const slides = modifiedQuote.map((quote, index) => {
      const modifiedIndex = index - quotes.length + carouselPosition;
      const slideState =
        modifiedIndex === carouselPosition
          ? styles.slideActive
          : modifiedIndex - carouselPosition > 0
          ? styles.slideComplete
          : styles.slideStandby;
      const logo = quote.logo ? (
        <Img fluid={quote.logo.fluid} alt={quote.publication} />
      ) : (
        <h4>{quote.publication}</h4>
      );
      return (
        <div
          key={`${quote.publication}-${modifiedIndex}`}
          className={css(styles.slide, slideState)}
          aria-hidden={modifiedIndex !== carouselPosition}
        >
          <div className={css(styles.slideWrapper)}>
            <h3 className={css(styles.slideHeadline)}>
              <a
                href={quote.link}
                className={css(styles.slideLink)}
                target="_blank"
                rel="noopener noreferrer"
                tabIndex={modifiedIndex !== carouselPosition ? '-1' : '0'}
              >
                {quote.copy}
              </a>
            </h3>
            <div className={css(styles.logoWrapper)}>{logo}</div>
          </div>
        </div>
      );
    });

    return (
      <>
        <div className={css(styles.media)}>
          <InView className={css(styles.mediaContent)} triggerOnce>
            {inView => {
              return (
                <>
                  <Reveal
                    animateOpacity
                    canPlay={inView}
                    from="bottom"
                    duration={1275}
                    ease="cubic-bezier(0,0.4,0.4,1)"
                    offset="45px"
                  >
                    <p className={css(styles.eyebrow, inView && styles.inView)}>{title}</p>
                  </Reveal>
                  <Swipeable
                    className={css(styles.carousel, inView && styles.carouselInView)}
                    onSwipedRight={() => this.previousSlide()}
                    onSwipedLeft={() => this.nextSlide()}
                    {...config}
                  >
                    {slides}
                  </Swipeable>
                  <CarouselButton
                    color="orange"
                    onClick={this.previousSlide}
                    inView={inView}
                    className={css(styles.button)}
                    flipped
                  />
                  <CarouselButton
                    color="yellow"
                    onClick={this.nextSlide}
                    inView={inView}
                    className={css(styles.button)}
                  />
                  <div
                    className={css(
                      styles.downloadLinksMobile,
                      inView && styles.downloadLinksMobileVisible
                    )}
                  >
                    <div className={css(styles.downloadLinksMobileOverlay)}>
                      Downloads
                      <svg
                        height="13.99"
                        viewBox="0 0 13.523 13.99"
                        width="13.523"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g fill="none" strokeMiterlimit="10">
                          <path d="m0 13.089h13.523v.902h-13.523z" />
                        </g>
                        <g fill="none" strokeMiterlimit="10">
                          <path
                            d="m0 0 5.7 4.889-5.7 5.703"
                            transform="matrix(0 1 -1 0 12.145 3.499)"
                          />
                          <path d="m9.203 0h-9.203" transform="matrix(0 1 -1 0 7.185 0)" />
                        </g>
                      </svg>
                    </div>
                    <select
                      ref={r => {
                        this.downloadSelectRef = r;
                      }}
                      defaultValue="null"
                      className={css(styles.downloadLinksMobileSelect)}
                    >
                      <option disabled value="null">
                        Downloads
                      </option>
                      {downloadOptions}
                    </select>
                  </div>
                  <Reveal
                    animateOpacity
                    canPlay={inView}
                    from="bottom"
                    duration={1275}
                    delay={1500}
                    ease="cubic-bezier(0,0.4,0.4,1)"
                    offset="45px"
                  >
                    <div
                      className={css(
                        styles.downloadLinksList,
                        downloadLinks.length < 3 && styles.downloadLinksListCentered,
                        downloadLinks.length === 4 && styles.downloadLinksListSpecialLayout
                      )}
                    >
                      {downloadLinks}
                    </div>
                  </Reveal>
                </>
              );
            }}
          </InView>
        </div>
      </>
    );
  }
}

export default Media;
