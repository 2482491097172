/* eslint-disable react/no-danger */
import React from 'react';
import InView from '@shared/InView/InView';
import Reveal from '@shared/Reveals/Reveal';
import Img from 'gatsby-image';

import { css, isIE } from '@utils';

import styles from './BrandStory.module.scss';

const formatCopy = copy => {
  return copy
    .replace(/\n\n/g, `<br/><br/>`)
    .replace(
      /\n/g,
      `<span class="${css(styles.singleBreak)}"><span class="${css(
        styles.singleBreakSpace
      )}"> </span><span class="${css(styles.singleBreakReturn)}"><br/></span></span>`
    );
};

class BrandStoryMask extends React.PureComponent {
  render() {
    const { children, className, wordClass, wordRevealedClass, playReveal } = this.props;

    const words = children.split(' ');
    const wordElements = words.map((word, index) => {
      const copy = formatCopy(index + 1 === words.length ? word : `${word} `);
      return (
        <span
          key={`${index}-${word}`}
          className={css(wordClass, playReveal && wordRevealedClass)}
          style={{
            transition: `opacity 1s ${index * 10 + 200}ms ease-in-out`
          }}
          dangerouslySetInnerHTML={{ __html: copy }}
        />
      );
    });
    return <div className={className}>{wordElements}</div>;
  }
}

const BrandStory = props => {
  const { copy } = props;
  const { brandLogo } = props;
  const formattedCopy = formatCopy(copy);
  const browserIsIE = isIE();

  return (
    <>
      <InView triggerOnce>
        {inView => {
          return <div className={css(styles.sectionDivider, inView && styles.inView)} />;
        }}
      </InView>
      <div className={css(styles.brandStory)}>
        <div className={css(styles.imageSection)}>
          <InView triggerOnce>
            {inView => {
              return (
                <>
                  <Reveal
                    animateOpacity
                    canPlay={inView}
                    from="bottom"
                    duration={1275}
                    ease="cubic-bezier(0,0.4,0.4,1)"
                    offset="45px"
                  >
                    <Img
                      className={css(styles.wordMarkLogo)}
                      fluid={brandLogo}
                      alt="Parallel Logo"
                    />
                  </Reveal>
                </>
              );
            }}
          </InView>
        </div>
        <div className={css(styles.copySection)}>
          <InView triggerOnce>
            {inView => {
              return (
                <div className={css(styles.copyWrapper, inView && styles.inView)}>
                  <div
                    className={css(browserIsIE && styles.gradientCopyIE, styles.gradientCopy)}
                    dangerouslySetInnerHTML={{ __html: formattedCopy }}
                  />
                  <BrandStoryMask
                    className={css(styles.maskCopy)}
                    wordClass={styles.maskCopyWord}
                    wordRevealedClass={styles.maskCopyWordRevealed}
                    playReveal={inView}
                  >
                    {copy}
                  </BrandStoryMask>
                </div>
              );
            }}
          </InView>
        </div>
      </div>
    </>
  );
};

export default BrandStory;
