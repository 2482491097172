import React from 'react';
import ImageReveal from '@shared/ImageReveal/ImageReveal';
import Parallax from '@shared/Parallax/Parallax';
import InView from '@shared/InView/InView';
import Reveal from '@shared/Reveals/Reveal';
import { css } from '@utils';
import Seal from './Seal';

import styles from './StoryIntroduction.module.scss';

class StoryIntroduction extends React.PureComponent {
  render() {
    const { primaryImage, secondaryImage, tertiaryImage, headline, copy } = this.props;
    return (
      <div className={css(styles.wrapper)}>
        <div className={css(styles.left)}>
          <div className={css(styles.leftText)}>
            <InView className={css(styles.inViewWrapper)} triggerOnce>
              {inView => {
                return (
                  <>
                    <Reveal
                      animateOpacity
                      canPlay={inView}
                      from="bottom"
                      delay={50}
                      duration={1275}
                      ease="cubic-bezier(0,0.4,0.4,1)"
                      offset="45px"
                    >
                      <h2 className={css(styles.headline, inView && styles.inView)}>
                        {[headline]}
                      </h2>
                    </Reveal>

                    <Reveal
                      animateOpacity
                      canPlay={inView}
                      from="bottom"
                      delay={100}
                      duration={1275}
                      ease="cubic-bezier(0,0.4,0.4,1)"
                      offset="45px"
                    >
                      <p className={css(styles.copy)}>{copy}</p>
                    </Reveal>
                  </>
                );
              }}
            </InView>
          </div>
          <div className={css(styles.leftImages)}>
            <div className={css(styles.tertiaryImageWrapper)}>
              <Parallax>
                <ImageReveal
                  alt={tertiaryImage.description}
                  image={tertiaryImage.fluid}
                  className={styles.tertiaryImage}
                  triggerOnce
                />
              </Parallax>
            </div>
            <Seal />
          </div>
        </div>
        <div className={css(styles.right)}>
          <div className={css(styles.rightImages)}>
            <div className={css(styles.primaryImageWrapper)}>
              <Parallax>
                <ImageReveal
                  alt={primaryImage.description}
                  image={primaryImage.fluid}
                  className={styles.primaryImage}
                  triggerOnce
                />
              </Parallax>
            </div>
            <div className={css(styles.secondaryImageWrapper)}>
              <Parallax>
                <ImageReveal
                  alt={secondaryImage.description}
                  image={secondaryImage.fluid}
                  className={styles.secondaryImage}
                  triggerOnce
                />
              </Parallax>
            </div>
          </div>
        </div>

        <InView triggerOnce>
          {inView => {
            return <div className={css(styles.sectionDivider, inView && styles.inView)} />;
          }}
        </InView>
      </div>
    );
  }
}

export default StoryIntroduction;
