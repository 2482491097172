import React from 'react';
import { graphql } from 'gatsby';
import idx from 'idx';
import BrandStory from './BrandStory';

const BrandStoryQuery = props => {
  const copy = idx(props, _ => _.copy.brandStory);
  const brandLogo = idx(props, _ => _.brandLogo.childImageSharp.fluid);
  return <BrandStory copy={copy} brandLogo={brandLogo} />;
};

export default BrandStoryQuery;

export const query = graphql`
  fragment BrandStory on ContentfulHome {
    brandStory {
      brandStory
    }
  }
`;

export const logoQuery = graphql`
  fragment BrandStoryLogo on Query {
    file(relativePath: { eq: "images/Parallel_Symbol_RGB_Charcoal.png" }) {
      childImageSharp {
        fluid(maxWidth: 200, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`;
