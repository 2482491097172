import React from 'react';
import ScrollContext from '@context/ScrollContext';

import styles from './Parallax.module.scss';

class Parallax extends React.PureComponent {
  constructor() {
    super();
    this.ismounted = false;
    this.parallax = 0;
    this.onScroll = this.onScroll.bind(this);
    this.onResize = this.onResize.bind(this);
    this.topPos = 0;
    this.bounding = {};

    this.amplitude = 15; //percentage
  }

  componentDidMount() {
    this.ismounted = true;
    window.addEventListener('resize', this.onResize);
    this.onResize();
    this.context.addScrollCallback(this.onScroll);
  }

  componentWillUnmount() {
    this.ismounted = false;
    this.context.removeScrollCallback(this.onScroll);
    window.removeEventListener('resize', this.onResize);
  }

  onResize() {
    this.bounding = this.ref.getBoundingClientRect();
    this.topPos = this.bounding.top + (this.scrollTop || 0);
  }

  onScroll({ scrollTop, windowInnerHeight }) {
    this.scrollTop = scrollTop;
    this.parallax = (this.topPos - scrollTop + this.bounding.height / 2) / windowInnerHeight - 0.5;

    if (Math.abs(this.parallax) < 0.5) {
      this.refParallax.style.transform = `translate3d(0,${this.parallax *
        this.amplitude}%,0) scale(${1.0 + this.amplitude / 100})`;
    }
  }

  render() {
    const { children } = this.props;

    return (
      <div ref={r => (this.ref = r)} className={styles.parallaxWrapper}>
        <div
          ref={r => (this.refParallax = r)}
          style={{
            transform: `translate3d(0,0,0) scale(${1.0 + this.amplitude / 100}%)`
          }}
        >
          {children}
        </div>
      </div>
    );
  }
}

Parallax.contextType = ScrollContext;
export default Parallax;
