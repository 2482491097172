import React from 'react';
import ReactDOM from 'react-dom';
import Img from 'gatsby-image';
import InView from '@shared/InView/InView';
import { throttle } from 'throttle-debounce';
import { Swipeable } from 'react-swipeable';
import PersonnelBio from '@shared/PersonnelBio/PersonnelBio';
import CarouselButton from '@shared/CarouselButton/CarouselButton';
import { css, q, breakpoint, trackEvent } from '@utils';
import styles from './PersonnelCarousel.module.scss';

const IMAGE_WIDTH = 330;
const IMAGE_SPACING = 15;
const VISIBLE_IMAGES = 5;

const IMAGE_WIDTH_MOBILE = 315;
const IMAGE_SPACING_MOBILE = 10;
const VISIBLE_IMAGES_MOBILE = 3;

const SWIPE_CONFIG = {
  delta: 10, // min distance(px) before a swipe starts
  preventDefaultTouchmoveEvent: false, // preventDefault on touchmove, *See Details*
  trackTouch: true, // track touch input
  trackMouse: true // track mouse input
};

export default class PersonnelCarousel extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      carouselPosition: 0,
      windowWidth: 0,
      bioVisible: false
    };
    this.portalContainer = null;
  }

  componentDidMount() {
    this.portalContainer = q('#layout');
    this.onResize();
    this.addEventListeners();
  }

  setPerson = throttle(500, true, index => {
    const newPerson = index;
    this.setState({ carouselPosition: newPerson });
  });

  addEventListeners = () => {
    window.addEventListener('resize', this.onResize);
  };

  removeEventListeners = () => {
    window.removeEventListener('resize', this.onResize);
  };

  previousPerson = () => {
    const { carouselPosition } = this.state;
    this.setPerson(carouselPosition - 1);
  };

  nextPerson = () => {
    const { carouselPosition } = this.state;
    this.setPerson(carouselPosition + 1);
  };

  onResize = () => {
    this.setState({ windowWidth: window.innerWidth });
  };

  openBio = e => {
    e.preventDefault();
    this.trackBioClick();
    this.setState({ bioVisible: true });

    // -- block background page scroll on bio modal scroll
    if (document) {
      document.body.style.overflow = 'hidden';
    }
  };

  closeBio = e => {
    e.preventDefault();
    this.setState({ bioVisible: false });

    if (document) {
      document.body.style.overflow = '';
    }
  };

  trackBioClick = () => {
    const { carouselPosition } = this.state;
    const { personnel } = this.props;
    const currentPersonIndex = carouselPosition % personnel.length;

    const adjustedIndex =
      carouselPosition % personnel.length < 0
        ? personnel.length + (carouselPosition % personnel.length)
        : currentPersonIndex;

    const currentPerson = personnel[adjustedIndex];

    trackEvent(
      { category: 'Leadership', action: 'Expand', label: currentPerson.name },
      { position: carouselPosition + 1 }
    );
  };

  componentDidUmount() {
    this.removeEventListeners();
  }

  render() {
    const { carouselPosition, windowWidth, bioVisible } = this.state;
    const { personnel, currentSection } = this.props;

    const config = SWIPE_CONFIG;

    let imageWidth = IMAGE_WIDTH_MOBILE;
    let imageSpacing = IMAGE_SPACING_MOBILE;
    let visibleImages = VISIBLE_IMAGES_MOBILE;

    if (windowWidth >= breakpoint.tabletWide) {
      imageWidth = IMAGE_WIDTH;
      imageSpacing = IMAGE_SPACING;
      visibleImages = VISIBLE_IMAGES;
    }

    const currentPerson = carouselPosition % personnel.length;
    const currentPersonAbsolute =
      carouselPosition % personnel.length < 0
        ? personnel.length + (carouselPosition % personnel.length)
        : currentPerson;
    const personnelHead = currentPerson === 0 ? [] : personnel.slice(0, currentPerson);
    const personnelTail = personnel.slice(currentPerson);
    const reorderedPersonnel = personnelTail.concat(personnelHead);

    const modifiedPersonnel = reorderedPersonnel.concat(
      reorderedPersonnel.concat(reorderedPersonnel)
    );
    const personnelImages = modifiedPersonnel.map((person, index) => {
      const modifiedIndex = index - personnel.length + carouselPosition;
      const imageOffset = (modifiedIndex - carouselPosition) * (imageWidth + imageSpacing);
      const imageOpacity =
        (visibleImages * 0.5 - Math.abs(modifiedIndex - carouselPosition)) / (visibleImages * 0.5);
      const imageZIndex = modifiedIndex === carouselPosition ? 3 : 1;
      return (
        <div
          role="presentation"
          key={`${person.name}-${person.title}-${modifiedIndex}`}
          data-distance={Math.abs(modifiedIndex - carouselPosition)}
          className={css(styles.imageContainer, imageOpacity <= 0 && styles.imageContainerDisabled)}
          aria-hidden={modifiedIndex !== carouselPosition}
          style={{
            zIndex: imageZIndex
          }}
        >
          <div
            role="presentation"
            className={css(styles.imageWrapper)}
            style={{
              transform: `translate3d(${imageOffset}px, 0, 0)`,
              opacity: imageOpacity
            }}
            onClick={e => {
              if (modifiedIndex === carouselPosition) {
                this.openBio(e);
              } else {
                this.setPerson(carouselPosition + (modifiedIndex - carouselPosition));
              }
            }}
          >
            <div className={css(styles.imageBackground)}>
              <Img
                className={css(styles.image)}
                fluid={person.photo.fluid}
                alt={`${person.name}, ${person.title}`}
              />
            </div>
          </div>
        </div>
      );
    });

    const personnelCopy = modifiedPersonnel.map((person, index) => {
      const modifiedIndex = index - personnel.length + carouselPosition;
      return (
        <div
          key={`${person.name}-${person.title}-${modifiedIndex}`}
          className={css(styles.info, modifiedIndex === carouselPosition && styles.infoActive)}
          aria-hidden={modifiedIndex !== carouselPosition}
        >
          <h5 className={css(styles.headline)}>
            <div className={css(styles.name)}>{person.name}</div>
            <div className={css(styles.title)}>{person.title}</div>
          </h5>
          <p className={css(styles.bio)}>{person.bioSummary}</p>
          <a
            className={css(styles.more)}
            tabIndex={modifiedIndex !== carouselPosition ? '-1' : '0'}
            href={`#${person.name}`}
            onClick={this.openBio}
          >
            More +
          </a>
        </div>
      );
    });

    const activePerson = personnel[currentPersonAbsolute];

    return (
      <>
        {this.portalContainer &&
          activePerson &&
          ReactDOM.createPortal(
            <PersonnelBio
              name={activePerson.name}
              title={activePerson.title}
              photo={activePerson.photo}
              bio={activePerson.bio}
              currentSection={currentSection}
              visible={bioVisible}
              hideBio={this.closeBio}
            />,
            this.portalContainer
          )}
        <div className={css(styles.carousel)}>
          <InView triggerOnce>
            {inView => {
              return (
                <>
                  <Swipeable
                    className={css(styles.carouselImages)}
                    onSwipedRight={() => this.previousPerson()}
                    onSwipedLeft={() => this.nextPerson()}
                    {...config}
                  >
                    <div
                      className={css(
                        styles.carouselImagesWrapper,
                        inView && styles.carouselImagesWrapperInView
                      )}
                    >
                      {personnelImages}
                    </div>
                    <CarouselButton
                      color="blue"
                      onClick={this.previousPerson}
                      inView={inView}
                      flipped
                    />
                    <CarouselButton color="yellow" onClick={this.nextPerson} inView={inView} />
                  </Swipeable>
                  <div className={css(styles.carouselCopy, inView && styles.carouselCopyInView)}>
                    {personnelCopy}
                  </div>
                </>
              );
            }}
          </InView>
        </div>
      </>
    );
  }
}
