import React from 'react';
import ImageReveal from '@shared/ImageReveal/ImageReveal';
import CharacterReveal from '@shared/Reveals/CharacterReveal';
import Reveal from '@shared/Reveals/Reveal';
import InView from '@shared/InView/InView';
import LogoSymbol from '@shared/Logos/LogoSymbol';
import HamburgerMenu from '@shared/HamburgerMenu/HamburgerMenu';
import GradientBackground from '@shared/GradientBackground/GradientBackground';
import { css } from '@utils';

import styles from './PersonnelBio.module.scss';

class PersonnelBio extends React.PureComponent {
  componentDidUpdate = () => {
    const { visible } = this.props;
    if (visible) {
      this.bioRef.focus();
    }
  };

  render() {
    const { name, title, photo, bio, currentSection, visible, hideBio } = this.props;

    return (
      <>
        <div
          className={css(styles.wrapper, visible && styles.visible)}
          role="group"
          style={{ outline: 'none' }}
          tabIndex="-1"
          aria-hidden={!visible}
          ref={r => {
            this.bioRef = r;
          }}
        >
          <GradientBackground currentStep={currentSection} />
          <div className={css(styles.header)}>
            <div role="presentation" className={css(styles.logoSymbol)}>
              <LogoSymbol />
            </div>
            <HamburgerMenu onClick={hideBio} active tabIndex={visible ? '1' : '-1'} />
          </div>
          <div className={css(styles.person)}>
            <div className={css(styles.left)}>
              <div className={css(styles.photoWrapper)}>
                <ImageReveal
                  className={css(styles.photo)}
                  image={photo.fluid}
                  imgStyle={{ objectPosition: 'top' }}
                  alt={`${name}, ${title}`}
                />
              </div>
            </div>
            <div className={css(styles.right)} key={name}>
              <div
                className={css(styles.copyWrapper, 'alt-focus-visible')}
                role="region"
                tabIndex={visible ? '1' : '-1'}
              >
                <InView>
                  {inView => {
                    return (
                      <>
                        <div className={css(styles.name, inView && styles.inView)}>
                          <CharacterReveal
                            animateOpacity
                            canPlay={inView}
                            characterOffsetDelay={15}
                            characterWordSpacing="10px"
                            copy={[name]}
                            from="bottom"
                            delay={500}
                            duration={700}
                            ease="cubic-bezier(0,0.4,0.4,1)"
                            offset="25px"
                            multilineMasking={false}
                            multilineOffsetDelay={100}
                          />
                        </div>
                        <Reveal
                          animateOpacity
                          canPlay={inView}
                          from="bottom"
                          duration={1275}
                          ease="cubic-bezier(0,0.4,0.4,1)"
                          offset="45px"
                          delay={500}
                        >
                          <div className={css(styles.title, inView && styles.inView)}>{title}</div>
                        </Reveal>
                        <Reveal
                          animateOpacity
                          canPlay={inView}
                          from="bottom"
                          duration={1400}
                          ease="cubic-bezier(0,0.4,0.4,1)"
                          offset="45px"
                          delay={700}
                        >
                          <div
                            className={css(styles.bio, inView && styles.inView)}
                            // eslint-disable-next-line react/no-danger
                            dangerouslySetInnerHTML={{ __html: bio }}
                          />
                        </Reveal>
                      </>
                    );
                  }}
                </InView>
              </div>
              <div className={css(styles.copyFade)} />
            </div>
          </div>
          <div className={css(styles.footer)} />
        </div>
      </>
    );
  }
}

export default PersonnelBio;
