import React from 'react';
import ReactDOM from 'react-dom';
import LogoSymbol from '@shared/Logos/LogoSymbol';
import GradientBackground from '@shared/GradientBackground/GradientBackground';
import styles from './NavMenu.module.scss';
import { css, q } from '@utils';

class NavMenu extends React.PureComponent {
  constructor() {
    super();
    this.portalContainer = null;
  }

  componentDidMount() {
    this.portalContainer = q('#layout');
  }

  render() {
    const { open, currentSection, onLinkClick, onNavLogoClick, navLinks } = this.props;

    return (
      <div className={css(styles.navMenu, open && styles.open)} aria-hidden={!open}>
        <GradientBackground
          className={css(styles.gradientBackground)}
          currentStep={currentSection}
        />

        {this.portalContainer &&
          ReactDOM.createPortal(
            <button
              type="button"
              onClick={onNavLogoClick}
              className={css(styles.logoSymbol, open && styles.open)}
              tabIndex={open ? '1' : '-1'}
            >
              <LogoSymbol />
            </button>,

            this.portalContainer
          )}

        <div className={css(styles.navLinksList)}>
          {navLinks.map((link, index) => {
            return (
              <button
                type="button"
                key={`NavLink__${index}`}
                tabIndex={open ? '1' : '-1'}
                className={css(styles.navLink, open && styles.open)}
                onClick={() => onLinkClick(link.sectionName)}
              >
                {link.label}
              </button>
            );
          })}
        </div>
      </div>
    );
  }
}

export default NavMenu;
