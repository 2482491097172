import React from 'react';
import ReactDOM from 'react-dom';
import LogoWordMark from '@shared/Logos/LogoWordMark';
import HeaderCondensed from '@shared/HeaderCondensed/HeaderCondensed';
import HamburgerMenu from '@shared/HamburgerMenu/HamburgerMenu';
import NavMenu from '@shared/NavMenu/NavMenu';
import headerData from '@data/headerData';
import styles from './Header.module.scss';
import { css, q, scrollTo, constrain, trackEvent } from '@utils';

const MOBILE_BREAKPOINT = 1024;

class Header extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      pageMounted: false,
      portalContainer: null,
      windowWidth: 0,
      navOpen: false
    };
    this.bodyEl = null;
    this.previousScrollY = 0;
    this.bodyPositionTimeoutId = null;
    this.navLinks = this.mapNavLinks();
  }

  componentDidMount() {
    this.bodyEl = q('body');
    this.setState({ pageMounted: true, portalContainer: q('#layout') });
    window.addEventListener('resize', this.onResize);
    this.onResize();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.onResize);
    clearTimeout(this.bodyPositionTimeoutId);
  }

  onResize = () => {
    this.setState({ windowWidth: window.innerWidth });
  };

  onDesktopNavClick = sectionId => {
    trackEvent({ category: 'Header', action: 'Navigation', label: sectionId });
    this.scrollToSectionId(sectionId);
  };

  mapNavLinks = () => {
    const { navLinks } = this.props;

    const mappedHeaderData = headerData.navLinks.map(link => {
      const existingCustomNavLabel = navLinks.find(
        customLink => customLink.sectionId === link.sectionName
      );

      return {
        label: existingCustomNavLabel.displayLabel || link.label,
        sectionName: link.sectionName
      };
    });

    return mappedHeaderData;
  };

  scrollToSectionId = sectionId => {
    const sectionEl = q(`#${sectionId}`);
    const headerHeight = 60;
    const newScrollPosition = sectionEl ? sectionEl.offsetTop : 0;

    // Since our condensed header sits on top of the content, we should subtract its height from the new scroll position
    const scrollAdjustedForHeader = constrain(
      newScrollPosition - headerHeight,
      0,
      newScrollPosition
    );

    // This makes it so you don't fully animate ALL the way to the new section. It starts you 50px away and then eases you into the section.
    window.scrollTo(0, scrollAdjustedForHeader);

    if (sectionEl) {
      scrollTo(scrollAdjustedForHeader, 500);
    }
    sectionEl.focus();
  };

  toggleBodyPositionFixed = setFixed => {
    clearTimeout(this.bodyPositionTimeoutId);
    if (setFixed) {
      this.bodyPositionTimeoutId = setTimeout(() => {
        this.bodyEl.style.position = 'fixed';
      }, 200); // This timing matches the css animation
    } else {
      this.bodyEl.style.position = 'relative';
    }
  };

  onHamburgerMenuClick = () => {
    const { navOpen } = this.state;
    if (navOpen) {
      this.toggleBodyPositionFixed(false);
      window.scroll(0, this.previousScrollY);
    } else {
      this.previousScrollY = window.scrollY;
      this.toggleBodyPositionFixed(true);
    }
    this.setState({ navOpen: !navOpen });
  };

  onMobileNavLinkClick = sectionId => {
    this.setState({ navOpen: false });
    this.toggleBodyPositionFixed(false);
    window.scroll(0, this.previousScrollY);
    this.scrollToSectionId(sectionId);

    trackEvent({ category: 'Header', action: 'Navigation', label: sectionId });
  };

  onMobileNavLogoClick = () => {
    const { setPageSection } = this.props;
    setPageSection('Hero');
    this.setState({ navOpen: false });
    this.toggleBodyPositionFixed(false);
    window.scroll(0, this.previousScrollY);
    scrollTo(0, 0);
  };

  render() {
    const { currentSection } = this.props;
    const { pageMounted, portalContainer, windowWidth, navOpen } = this.state;
    const isMobile = windowWidth < MOBILE_BREAKPOINT;

    return (
      <header id="header">
        <div className={css(styles.headerMobile)}>
          <div className={css(styles.logoWordMark, styles.mobile, pageMounted && styles.visible)}>
            <LogoWordMark className={styles.svg} />
          </div>

          {isMobile &&
            portalContainer &&
            ReactDOM.createPortal(
              <HamburgerMenu onClick={this.onHamburgerMenuClick} active={navOpen} />,
              portalContainer
            )}
          {isMobile &&
            portalContainer &&
            ReactDOM.createPortal(
              <NavMenu
                open={navOpen}
                currentSection={currentSection}
                onLinkClick={this.onMobileNavLinkClick}
                onNavLogoClick={this.onMobileNavLogoClick}
                navLinks={this.navLinks}
              />,
              portalContainer
            )}
        </div>

        <div className={styles.headerDesktop}>
          <button
            className={css(styles.logoWordMark, pageMounted && styles.visible)}
            type="button"
            onClick={() => {
              window.location.reload();
            }}
            aria-label="Parallel"
          >
            <LogoWordMark className={styles.svg} />
          </button>
          <ul className={css(styles.linksList)}>
            {this.navLinks.map((link, index) => {
              return (
                <li key={`HeaderLink__${index}`} className={css(styles.linkWrapper)}>
                  <button
                    type="button"
                    onClick={() => this.onDesktopNavClick(link.sectionName)}
                    className={css(styles.link, pageMounted && styles.visible)}
                  >
                    {link.label}
                  </button>
                </li>
              );
            })}
          </ul>
        </div>

        <HeaderCondensed currentSection={currentSection} navLinks={this.navLinks} />
      </header>
    );
  }
}

export default Header;
