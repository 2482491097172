import React from 'react';
import Img from 'gatsby-image';
import InView from '@shared/InView/InView';
import styles from './ImageReveal.module.scss';
import { css } from '@utils';

class ImageReveal extends React.PureComponent {
  constructor() {
    super();
    this.state = { active: false };
    this.ismounted = false;
  }

  componentDidMount() {
    this.ismounted = true;
  }

  componentWillUnmount() {
    this.ismounted = false;
  }

  onInView = inView => {
    if (this.ismounted) {
      this.setState({ active: inView });
    }
  };

  render() {
    const { active } = this.state;
    const { image, className, imgStyle, style, triggerOnce, alt } = this.props;

    return (
      <InView
        className={css(styles.imageRevealWrapper, className)}
        onChange={this.onInView}
        triggerOnce={triggerOnce}
      >
        <Img
          className={css(styles.gatsbyImage, active && styles.active)}
          fluid={image}
          style={style}
          imgStyle={imgStyle}
          alt={alt}
        />
      </InView>
    );
  }
}

export default ImageReveal;
