import React from 'react';
import { graphql } from 'gatsby';
import idx from 'idx';
import StoryTabs from './StoryTabs';

const StoryTabsQuery = props => {
  const eyebrow = idx(props, _ => _.eyebrow);
  const headline = idx(props, _ => _.headline);
  const tabs = idx(props, _ => _.storyTabs).map(tab => {
    return {
      title: idx(tab, _ => _.title),
      headline: idx(tab, _ => _.headline),
      copy: idx(tab, _ => _.copy.copy),
      imageDesktop: idx(tab, _ => _.imageDesktop),
      imageMobile: idx(tab, _ => _.imageMobile)
    };
  });

  return <StoryTabs eyebrow={eyebrow} headline={headline} tabs={tabs} />;
};

export default StoryTabsQuery;

export const query = graphql`
  fragment StoryTabs on ContentfulStoryTabs {
    eyebrow
    headline
    storyTabs {
      title
      headline
      copy {
        copy
      }
      imageDesktop {
        description
        fluid(maxWidth: 900, quality: 90) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
      imageMobile {
        description
        fluid(maxWidth: 1536, quality: 90) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
    }
  }
`;
