import React from 'react';
import { graphql } from 'gatsby';
import idx from 'idx';
import Brands from './Brands';

const BrandsQuery = props => {
  const title = idx(props, _ => _.title);
  const headline = idx(props, _ => _.headline);
  const brandsData = idx(props, _ => _.brands);
  const brands = brandsData
    ? brandsData.map(brand => {
        const brandFormatted = {};
        brandFormatted.name = idx(brand, _ => _.name);
        brandFormatted.link = idx(brand, _ => _.link);
        brandFormatted.logo = idx(brand, _ => _.logo);
        brandFormatted.backgroundImage = idx(brand, _ => _.backgroundImage);
        brandFormatted.description = idx(brand, _ => _.description);
        return brandFormatted;
      })
    : [];
  return <Brands brands={brands} headline={headline} title={title} />;
};

export default BrandsQuery;

export const query = graphql`
  fragment Brands on ContentfulBrands {
    title
    headline
    brands {
      name
      link
      backgroundImage {
        description
        fluid(maxWidth: 540, quality: 80) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
      logo {
        description
        fluid(maxWidth: 500, quality: 100) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
      description
    }
  }
`;
