import React from 'react';
import { graphql } from 'gatsby';
import idx from 'idx';
import Leadership from './Leadership';

const LeadershipQuery = props => {
  const title = idx(props, _ => _.title);
  const headline = idx(props, _ => _.headline);
  const leadersData = idx(props, _ => _.leaders);
  const leaders = leadersData.map(leader => {
    return {
      name: idx(leader, _ => _.name),
      title: idx(leader, _ => _.title),
      photo: idx(leader, _ => _.photo),
      bio: idx(leader, _ => _.bio.childMarkdownRemark.html),
      bioSummary: idx(leader, _ => _.bioSummary.bioSummary)
    };
  });

  return <Leadership leaders={leaders} headline={headline} title={title} />;
};

export default LeadershipQuery;

export const query = graphql`
  fragment Leadership on ContentfulLeadership {
    title
    headline
    leaders {
      name
      title
      photo {
        fluid(maxWidth: 1110, quality: 90) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
      bio {
        childMarkdownRemark {
          html
        }
      }
      bioSummary {
        bioSummary
      }
    }
  }
`;
