import React from 'react';
import { graphql } from 'gatsby';
import idx from 'idx';
import StoryIntroduction from './StoryIntroduction';

const StoryIntroductionQuery = props => {
  const headline = idx(props, _ => _.headline);
  const body = idx(props, _ => _.body.body);
  const largeImage = idx(props, _ => _.largeImage);
  const smallImage = idx(props, _ => _.smallImage);
  const extraImage = idx(props, _ => _.extraImage);

  return (
    <StoryIntroduction
      headline={headline}
      copy={body}
      primaryImage={largeImage}
      secondaryImage={extraImage}
      tertiaryImage={smallImage}
    />
  );
};

export default StoryIntroductionQuery;

export const query = graphql`
  fragment StoryIntroduction on ContentfulStoryIntroduction {
    headline
    body {
      body
    }
    largeImage {
      description
      fluid(maxWidth: 1200, quality: 90) {
        ...GatsbyContentfulFluid_withWebp_noBase64
      }
    }
    smallImage {
      description
      fluid(maxWidth: 600, quality: 90) {
        ...GatsbyContentfulFluid_withWebp_noBase64
      }
    }
    extraImage {
      description
      fluid(maxWidth: 600, quality: 90) {
        ...GatsbyContentfulFluid_withWebp_noBase64
      }
    }
  }
`;
